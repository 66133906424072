import React from 'react';
import SEO from '../components/molecules/SEO';
import Gallery from '../components/organisms/Gallery';
import { graphql } from 'gatsby';
import Copy from '../components/organisms/Copy';
import styled from 'styled-components';
import Navigation from '../components/molecules/Navigation';
import RichTextConverter from '../components/atoms/RichTextConverter';

const Holder = styled.div`
  min-height: 100vh;
  color: ${props => props.scheme === 'dark' ? props.theme.colours.cream : props.theme.colours.darkGreen};
  background-color: ${props => props.scheme === 'dark' ? props.theme.colours.darkGreen : props.theme.colours.lightGreen};
  
  svg {
    path, polygon {
      fill: ${props => props.scheme === 'dark' ? props.theme.colours.cream : props.theme.colours.darkGreen};
    }
  }
`;

const IndexPage = ( { data } ) => {

  const { contentfulPage } = data;

  return (
    <Holder scheme={contentfulPage.colourScheme}>
      <SEO title={contentfulPage.title}/>
      <Copy>
        <RichTextConverter content={contentfulPage.content.json}/>
      </Copy>
      <Gallery images={contentfulPage.images}/>
      <Navigation/>
    </Holder>
  );
};

export default IndexPage;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulPage(id: { eq: $id }) {
            title
            content {
                json
            }
            images {
                fluid(maxWidth: 1000) {
                    ...GatsbyContentfulFluid
                }
            }
            colourScheme
        }
    }
`;


