import React, { Component } from 'react';
import { Link } from 'gatsby';
import IconDrinks from '../atoms/IconDrinks';
import IconGelato from '../atoms/IconGelato';
import IconCoffee from '../atoms/IconCoffee';
import IconGarden from '../atoms/IconGarden';
import styled from 'styled-components';

const Holder = styled.nav`
    ul {
        list-style: none;
        margin: 0;
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        pointer-events: none;
        @media( ${props => props.theme.breakpoints.md} ) {
          width: 50%;
        }
        li {
            .text {
                display: inline-block;
                position: absolute;
                z-index: 10;
                text-transform: uppercase;
                letter-spacing: 2px;
                ${props => props.theme.typeStyles(-1)};
                cursor: pointer;
                pointer-events: auto;
                a {
                  text-decoration: none;
                  &[aria-current="page"] {
                    text-decoration: underline;
                  }
                }
            }

            .icon {
                position: absolute;
                cursor: pointer;
                pointer-events: auto;
                svg {
                    width: 3rem;
                    height: auto;
                }
            }

            &.gelato {
                .text {
                    top: 1rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .icon {
                    top: 1rem;
                    left: 1rem;
                }
            }
            &.garden {
                .text {
                    top: 50%;
                    right: 1rem;
                    transform: translateX(50%) rotate(90deg);
                    transform-origin: top center;
                }
                .icon {
                    top: 1rem;
                    right: 1rem;
                }
            }
            &.drinks {
                .text {
                    top: 50%;
                    left: 1rem;
                    transform: translateX(-50%) rotate(-90deg);
                    transform-origin: top center;
                }
                .icon {
                    bottom: 1rem;
                    left: 1rem;
                }
            }
            &.coffee {
                .text {
                    bottom: 1rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .icon {
                    bottom: 1rem;
                    right: 1rem;
                }
            }
        }
    }
`;

class Navigation extends Component {
  render() {
    return (
      <Holder>
        <ul>
          <li className="drinks">
            <span className="text">
              <Link to="/food-and-drinks">Food & Drinks</Link>
            </span>
            <span className="icon">
              <Link to="/food-and-drinks">
                <IconDrinks/>
              </Link>
            </span>
          </li>
          <li className="gelato">
            <span className="text">
              <Link to="/gelato">Gelato</Link>
            </span>
            <span className="icon">
              <Link to="/gelato">
                <IconGelato/>
              </Link>
            </span>
          </li>
          <li className="coffee">
            <span className="text">
              <Link to="/coffee">Coffee</Link>
            </span>
            <span className="icon">
              <Link to="/coffee">
                <IconCoffee/>
              </Link>
            </span>
          </li>
          <li className="garden">
            <span className="text">
              <Link to="/garden">Garden & Events</Link>
            </span>
            <span className="icon">
              <Link to="/garden">
                <IconGarden/>
              </Link>
            </span>
          </li>
        </ul>
      </Holder>
    )
  }
}

export default Navigation;
