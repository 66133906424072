import React, { Component } from 'react';

class IconCoffee extends Component {
  render() {
    return (
      <svg width="47px" height="68px" viewBox="0 0 47 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Coffee</title>
        <g id="coffee" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M23.146,1 C10.935,1 1,15.645 1,33.646 C1,51.646 10.935,66.291 23.146,66.291 C35.357,66.291 45.291,51.646 45.291,33.646 C45.291,15.645 35.357,1 23.146,1 M23.146,67.291 C10.383,67.291 0,52.197 0,33.646 C0,15.094 10.383,0 23.146,0 C35.908,0 46.291,15.094 46.291,33.646 C46.291,52.197 35.908,67.291 23.146,67.291" id="Fill-205" fill="#023A25"/>
          <path d="M22.332,24.8798 C14.01,24.8798 8.699,26.5698 8.699,27.7338 C8.699,28.8968 14.01,30.5868 22.332,30.5868 C30.653,30.5868 35.964,28.8968 35.964,27.7338 C35.964,26.5698 30.653,24.8798 22.332,24.8798 M22.332,31.5868 C15.061,31.5868 7.699,30.2638 7.699,27.7338 C7.699,25.2028 15.061,23.8798 22.332,23.8798 C29.603,23.8798 36.964,25.2028 36.964,27.7338 C36.964,30.2638 29.603,31.5868 22.332,31.5868" id="Fill-206" fill="#023A25"/>
          <path d="M22.6748,49.7324 C19.4748,49.7324 16.3958,48.9934 15.3028,47.8774 C12.0178,45.6784 8.1458,29.6754 7.7128,27.8484 C7.6498,27.5804 7.8158,27.3104 8.0838,27.2474 C8.3538,27.1804 8.6208,27.3484 8.6858,27.6184 C9.8428,32.5024 13.3938,45.5144 15.8878,47.0644 C15.9268,47.0884 15.9618,47.1184 15.9918,47.1514 C17.6368,48.9424 26.0128,49.5764 29.3588,47.0994 C32.1898,45.0024 35.0968,32.2174 35.9788,27.4694 C36.0288,27.1974 36.2928,27.0164 36.5608,27.0694 C36.8318,27.1194 37.0118,27.3794 36.9608,27.6514 C36.8288,28.3644 33.6788,45.1434 29.9538,47.9024 C28.2068,49.1964 25.3968,49.7324 22.6748,49.7324" id="Fill-207" fill="#023A25"/>
          <path d="M35.3242,41.1045 C33.5312,41.1045 33.1882,40.5645 33.1302,40.2945 C33.0892,40.1055 33.2082,39.9185 33.3962,39.8775 C33.5862,39.8335 33.7702,39.9555 33.8132,40.1425 C33.8152,40.1285 34.0332,40.4065 35.3072,40.4065 C35.6552,40.4065 36.0802,40.3835 36.5982,40.3285 C37.9242,40.1835 38.5642,38.9355 38.8282,38.2045 C39.4142,36.5815 39.2402,34.7385 38.6942,34.1745 C37.6322,33.0785 35.5872,33.0895 35.5592,33.0895 L35.5542,33.0895 C35.3642,33.0895 35.2082,32.9375 35.2052,32.7455 C35.2022,32.5525 35.3552,32.3935 35.5492,32.3905 C35.6412,32.4005 37.9142,32.3655 39.1962,33.6865 C39.9892,34.5035 40.1222,36.6815 39.4872,38.4425 C38.9352,39.9695 37.9362,40.8865 36.6752,41.0235 C36.1512,41.0805 35.7052,41.1045 35.3242,41.1045" id="Fill-208" fill="#023A25"/>
          <path d="M35.6162,39.3867 C34.6322,39.3867 34.0452,38.9687 34.0122,38.9457 C33.8572,38.8307 33.8232,38.6127 33.9372,38.4557 C34.0532,38.2987 34.2722,38.2677 34.4272,38.3807 C34.4562,38.4017 35.2212,38.9267 36.4952,38.5567 C36.8652,38.4497 37.2452,37.8897 37.4422,37.1627 C37.6432,36.4217 37.6032,35.6877 37.3462,35.4177 C36.7292,34.7677 35.3742,34.8207 35.3612,34.8217 C35.1862,34.8257 35.0052,34.6807 34.9962,34.4877 C34.9872,34.2947 35.1372,34.1307 35.3292,34.1217 C35.3972,34.1207 37.0152,34.0537 37.8542,34.9357 C38.3812,35.4907 38.3362,36.5447 38.1182,37.3467 C37.8472,38.3447 37.3132,39.0487 36.6902,39.2287 C36.2942,39.3437 35.9342,39.3867 35.6162,39.3867" id="Fill-209" fill="#023A25"/>
          <path d="M12.4228,30.2949 C12.3378,30.2949 12.2538,30.2649 12.1878,30.2039 C12.0458,30.0749 12.0328,29.8579 12.1598,29.7149 C12.2238,29.6439 13.7828,27.9659 19.8038,27.7489 C27.4328,27.4719 31.4258,28.0649 32.7528,29.6649 C32.8758,29.8149 32.8558,30.0339 32.7068,30.1579 C32.5588,30.2799 32.3378,30.2609 32.2138,30.1119 C30.5158,28.0639 22.4708,28.3519 19.8288,28.4479 C14.1788,28.6509 12.6938,30.1679 12.6808,30.1839 C12.6108,30.2569 12.5168,30.2949 12.4228,30.2949" id="Fill-210" fill="#023A25"/>
        </g>
      </svg>
    )
  }
}

export default IconCoffee;
