import React, { Component } from 'react';

class IconGarden extends Component {
  render() {
    return (
      <svg width="47px" height="68px" viewBox="0 0 47 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>garden</title>
        <g id="garden" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M23.8188,12.7175 C23.8298,12.8885 23.8368,13.0715 23.8368,13.2645 C23.8368,14.3955 24.0178,18.0445 24.1988,21.1575 C24.3458,23.7055 25.6378,24.4755 25.9548,24.6265 C25.9588,24.6235 25.9638,24.6195 25.9688,24.6145 C26.3988,24.2145 26.3988,22.0525 26.3988,21.2385 C26.3988,20.7655 26.1458,18.2295 25.3888,15.9105 C24.9048,14.4265 24.2928,13.3785 23.8188,12.7175 M25.9318,25.3355 C25.9148,25.3355 25.8958,25.3355 25.8788,25.3345 C25.8458,25.3315 25.8158,25.3255 25.7858,25.3155 C25.7008,25.2845 23.6928,24.5335 23.4998,21.1985 C23.3328,18.3035 23.1368,14.4885 23.1368,13.2645 C23.1368,12.3515 22.9668,11.6655 22.9648,11.6585 C22.9278,11.5105 22.9918,11.3545 23.1228,11.2745 C23.2548,11.1945 23.4208,11.2095 23.5358,11.3095 C23.5978,11.3635 25.0698,12.6765 26.0548,15.6945 C26.8478,18.1245 27.0978,20.7215 27.0978,21.2385 C27.0978,22.8985 27.0478,24.5665 26.4458,25.1265 C26.2978,25.2645 26.1218,25.3355 25.9318,25.3355" id="Fill-1" fill="#023A25"/>
          <path d="M25.9634,25.0925 C25.7794,25.0925 25.6264,24.9505 25.6134,24.7655 C25.6104,24.7105 25.2434,19.2075 24.3584,16.0785 C23.4694,12.9375 23.1594,12.0615 23.1564,12.0525 C23.0924,11.8705 23.1874,11.6705 23.3694,11.6055 C23.5524,11.5405 23.7504,11.6355 23.8174,11.8185 C23.8284,11.8535 24.1324,12.7115 25.0314,15.8875 C25.9384,19.0885 26.2974,24.4915 26.3134,24.7205 C26.3254,24.9125 26.1794,25.0795 25.9854,25.0925 L25.9634,25.0925 Z" id="Fill-3" fill="#023A25"/>
          <path d="M31.519,25.2498 C31.401,25.2498 31.285,25.1888 31.219,25.0798 C31.12,24.9138 31.173,24.6988 31.339,24.6008 L33.092,23.5498 C33.254,23.4498 33.472,23.5038 33.572,23.6698 C33.671,23.8358 33.618,24.0508 33.452,24.1488 L31.699,25.1998 C31.643,25.2338 31.581,25.2498 31.519,25.2498" id="Fill-5" fill="#023A25"/>
          <path d="M31.8462,25.7527 C31.6942,25.7527 31.5542,25.6537 31.5102,25.5007 C31.4562,25.3157 31.5632,25.1207 31.7482,25.0667 L33.2882,24.6197 C33.4742,24.5627 33.6682,24.6727 33.7222,24.8577 C33.7762,25.0437 33.6692,25.2377 33.4842,25.2917 L31.9442,25.7387 C31.9112,25.7487 31.8782,25.7527 31.8462,25.7527" id="Fill-7" fill="#023A25"/>
          <path d="M31.2534,24.6111 C31.1634,24.6111 31.0744,24.5781 31.0064,24.5091 C30.8694,24.3731 30.8684,24.1511 31.0054,24.0141 L32.4164,22.6001 C32.5534,22.4641 32.7744,22.4631 32.9114,22.5991 C33.0484,22.7361 33.0494,22.9581 32.9124,23.0941 L31.5014,24.5081 C31.4334,24.5771 31.3434,24.6111 31.2534,24.6111" id="Fill-9" fill="#023A25"/>
          <path d="M30.811,24.2019 C30.759,24.2019 30.707,24.1909 30.657,24.1669 C30.484,24.0819 30.412,23.8719 30.497,23.6979 L31.337,21.9799 C31.422,21.8059 31.632,21.7349 31.806,21.8199 C31.979,21.9049 32.051,22.1149 31.966,22.2889 L31.125,24.0069 C31.065,24.1309 30.94,24.2019 30.811,24.2019" id="Fill-11" fill="#023A25"/>
          <path d="M30.0102,24.0877 C29.9802,24.0877 29.9492,24.0837 29.9182,24.0757 C29.7322,24.0257 29.6212,23.8337 29.6722,23.6467 L30.0812,22.1327 C30.1312,21.9467 30.3212,21.8397 30.5112,21.8867 C30.6972,21.9377 30.8082,22.1287 30.7572,22.3167 L30.3482,23.8297 C30.3062,23.9847 30.1642,24.0877 30.0102,24.0877" id="Fill-13" fill="#023A25"/>
          <path d="M29.4526,24.2439 C29.2746,24.2439 29.1236,24.1099 29.1046,23.9309 L28.9306,22.2539 C28.9096,22.0609 29.0496,21.8899 29.2416,21.8699 C29.4346,21.8399 29.6056,21.9899 29.6256,22.1809 L29.8006,23.8579 C29.8206,24.0509 29.6816,24.2229 29.4886,24.2419 C29.4766,24.2439 29.4646,24.2439 29.4526,24.2439" id="Fill-15" fill="#023A25"/>
          <path d="M28.894,24.6599 C28.762,24.6599 28.636,24.5859 28.577,24.4589 L27.605,22.3849 C27.523,22.2099 27.599,22.0009 27.774,21.9189 C27.948,21.8349 28.158,21.9119 28.24,22.0879 L29.211,24.1619 C29.293,24.3369 29.217,24.5459 29.042,24.6279 C28.995,24.6499 28.944,24.6599 28.894,24.6599" id="Fill-17" fill="#023A25"/>
          <path d="M30.4909,20.6947 C30.3629,20.6817 30.2489,20.7747 30.2369,20.9017 C30.2239,21.0297 30.3179,21.1427 30.4449,21.1557 C30.5729,21.1677 30.6869,21.0747 30.6989,20.9477 C30.7119,20.8207 30.6189,20.7077 30.4909,20.6947" id="Fill-19" fill="#023A25"/>
          <path d="M29.9661,21.2768 C29.8381,21.2638 29.7241,21.3568 29.7121,21.4838 C29.6991,21.6118 29.7931,21.7248 29.9201,21.7378 C30.0481,21.7498 30.1621,21.6568 30.1741,21.5298 C30.1871,21.4028 30.0941,21.2898 29.9661,21.2768" id="Fill-21" fill="#023A25"/>
          <path d="M29.7062,20.2575 C29.5782,20.2445 29.4642,20.3375 29.4522,20.4645 C29.4392,20.5925 29.5332,20.7055 29.6602,20.7185 C29.7882,20.7305 29.9022,20.6375 29.9142,20.5105 C29.9272,20.3835 29.8342,20.2705 29.7062,20.2575" id="Fill-23" fill="#023A25"/>
          <path d="M28.8655,20.3856 C28.7375,20.3726 28.6235,20.4656 28.6115,20.5926 C28.5985,20.7206 28.6925,20.8336 28.8195,20.8466 C28.9475,20.8586 29.0615,20.7656 29.0735,20.6386 C29.0865,20.5116 28.9935,20.3986 28.8655,20.3856" id="Fill-25" fill="#023A25"/>
          <path d="M28.0292,20.6832 C27.9012,20.6702 27.7872,20.7632 27.7752,20.8902 C27.7622,21.0182 27.8562,21.1312 27.9832,21.1442 C28.1112,21.1562 28.2252,21.0632 28.2372,20.9362 C28.2502,20.8092 28.1572,20.6962 28.0292,20.6832" id="Fill-27" fill="#023A25"/>
          <path d="M28.918,21.4235 C28.79,21.4105 28.676,21.5035 28.664,21.6305 C28.651,21.7585 28.745,21.8715 28.872,21.8845 C29,21.8965 29.114,21.8035 29.126,21.6765 C29.139,21.5495 29.046,21.4365 28.918,21.4235" id="Fill-29" fill="#023A25"/>
          <path d="M30.581,21.285 C30.453,21.272 30.339,21.365 30.327,21.492 C30.314,21.62 30.408,21.733 30.535,21.746 C30.663,21.758 30.777,21.665 30.789,21.538 C30.802,21.411 30.709,21.298 30.581,21.285" id="Fill-31" fill="#023A25"/>
          <path d="M33.1647,22.207 C33.0367,22.194 32.9227,22.287 32.9107,22.414 C32.8977,22.542 32.9917,22.655 33.1187,22.668 C33.2467,22.68 33.3607,22.587 33.3727,22.46 C33.3857,22.333 33.2927,22.22 33.1647,22.207" id="Fill-33" fill="#023A25"/>
          <path d="M34.3308,23.295 C34.2028,23.282 34.0888,23.375 34.0768,23.502 C34.0638,23.63 34.1578,23.743 34.2848,23.756 C34.4128,23.768 34.5268,23.675 34.5388,23.548 C34.5518,23.421 34.4588,23.308 34.3308,23.295" id="Fill-35" fill="#023A25"/>
          <path d="M34.7275,24.4336 C34.5995,24.4206 34.4855,24.5136 34.4735,24.6406 C34.4605,24.7686 34.5545,24.8816 34.6815,24.8946 C34.8095,24.9066 34.9235,24.8136 34.9355,24.6866 C34.9485,24.5596 34.8555,24.4466 34.7275,24.4336" id="Fill-37" fill="#023A25"/>
          <path d="M29.3644,20.9213 C29.2364,20.9083 29.1224,21.0013 29.1104,21.1283 C29.0974,21.2563 29.1914,21.3693 29.3184,21.3823 C29.4464,21.3943 29.5604,21.3013 29.5724,21.1743 C29.5854,21.0473 29.4924,20.9343 29.3644,20.9213" id="Fill-39" fill="#023A25"/>
          <path d="M28.553,20.9677 C28.425,20.9547 28.311,21.0477 28.299,21.1747 C28.286,21.3027 28.38,21.4157 28.507,21.4287 C28.635,21.4407 28.749,21.3477 28.761,21.2207 C28.774,21.0937 28.681,20.9807 28.553,20.9677" id="Fill-41" fill="#023A25"/>
          <path d="M34.548,25.0655 C34.42,25.0525 34.306,25.1455 34.294,25.2725 C34.281,25.4005 34.375,25.5135 34.502,25.5265 C34.63,25.5385 34.744,25.4455 34.756,25.3185 C34.769,25.1915 34.676,25.0785 34.548,25.0655" id="Fill-43" fill="#023A25"/>
          <path d="M35.0312,25.2467 C34.9032,25.2337 34.7892,25.3267 34.7772,25.4537 C34.7642,25.5817 34.8582,25.6947 34.9852,25.7077 C35.1132,25.7197 35.2272,25.6267 35.2392,25.4997 C35.2522,25.3727 35.1592,25.2597 35.0312,25.2467" id="Fill-45" fill="#023A25"/>
          <path d="M34.1246,24.7332 C33.9966,24.7202 33.8826,24.8132 33.8706,24.9402 C33.8576,25.0682 33.9516,25.1812 34.0786,25.1942 C34.2066,25.2062 34.3206,25.1132 34.3326,24.9862 C34.3456,24.8592 34.2526,24.7462 34.1246,24.7332" id="Fill-47" fill="#023A25"/>
          <path d="M34.6363,23.8538 C34.5083,23.8408 34.3943,23.9338 34.3823,24.0608 C34.3693,24.1888 34.4633,24.3018 34.5903,24.3148 C34.7183,24.3268 34.8323,24.2338 34.8443,24.1068 C34.8573,23.9798 34.7643,23.8668 34.6363,23.8538" id="Fill-49" fill="#023A25"/>
          <path d="M28.0845,21.3441 C27.9565,21.3311 27.8425,21.4241 27.8305,21.5511 C27.8175,21.6791 27.9115,21.7921 28.0385,21.8051 C28.1665,21.8171 28.2805,21.7241 28.2925,21.5971 C28.3055,21.4701 28.2125,21.3571 28.0845,21.3441" id="Fill-51" fill="#023A25"/>
          <path d="M31.9134,21.183 C31.7854,21.17 31.6714,21.263 31.6594,21.39 C31.6464,21.518 31.7404,21.631 31.8674,21.644 C31.9954,21.656 32.1094,21.563 32.1214,21.436 C32.1344,21.309 32.0414,21.196 31.9134,21.183" id="Fill-53" fill="#023A25"/>
          <path d="M33.2513,21.669 C33.1233,21.656 33.0093,21.749 32.9973,21.876 C32.9843,22.004 33.0783,22.117 33.2053,22.13 C33.3333,22.142 33.4473,22.049 33.4593,21.922 C33.4723,21.795 33.3793,21.682 33.2513,21.669" id="Fill-55" fill="#023A25"/>
          <path d="M33.5757,22.9876 C33.4477,22.9746 33.3337,23.0676 33.3217,23.1946 C33.3087,23.3226 33.4027,23.4356 33.5297,23.4486 C33.6577,23.4606 33.7717,23.3676 33.7837,23.2406 C33.7967,23.1136 33.7037,23.0006 33.5757,22.9876" id="Fill-57" fill="#023A25"/>
          <path d="M34.0311,23.9629 C33.9031,23.9499 33.7891,24.0429 33.7771,24.1699 C33.7641,24.2979 33.8581,24.4109 33.9851,24.4239 C34.1131,24.4359 34.2271,24.3429 34.2391,24.2159 C34.2521,24.0889 34.1591,23.9759 34.0311,23.9629" id="Fill-59" fill="#023A25"/>
          <path d="M33.802,22.4181 C33.674,22.4051 33.56,22.4981 33.548,22.6251 C33.535,22.7531 33.629,22.8661 33.756,22.8791 C33.884,22.8911 33.998,22.7981 34.01,22.6711 C34.023,22.5441 33.93,22.4311 33.802,22.4181" id="Fill-61" fill="#023A25"/>
          <path d="M32.3042,21.6778 C32.1762,21.6648 32.0622,21.7578 32.0502,21.8848 C32.0372,22.0128 32.1312,22.1258 32.2582,22.1388 C32.3862,22.1508 32.5002,22.0578 32.5122,21.9308 C32.5252,21.8038 32.4322,21.6908 32.3042,21.6778" id="Fill-63" fill="#023A25"/>
          <path d="M32.8018,21.1563 C32.6738,21.1433 32.5598,21.2363 32.5478,21.3633 C32.5348,21.4913 32.6288,21.6043 32.7558,21.6173 C32.8838,21.6293 32.9978,21.5363 33.0098,21.4093 C33.0228,21.2823 32.9298,21.1693 32.8018,21.1563" id="Fill-65" fill="#023A25"/>
          <path d="M31.1263,21.1382 C30.9983,21.1252 30.8843,21.2182 30.8723,21.3452 C30.8593,21.4732 30.9533,21.5862 31.0803,21.5992 C31.2083,21.6112 31.3223,21.5182 31.3343,21.3912 C31.3473,21.2642 31.2543,21.1512 31.1263,21.1382" id="Fill-67" fill="#023A25"/>
          <path d="M31.3609,20.4849 C31.2329,20.4719 31.1189,20.5649 31.1069,20.6919 C31.0939,20.8199 31.1879,20.9329 31.3149,20.9459 C31.4429,20.9579 31.5569,20.8649 31.5689,20.7379 C31.5819,20.6109 31.4889,20.4979 31.3609,20.4849" id="Fill-69" fill="#023A25"/>
          <path d="M20.5034,42.1824 C19.5664,42.1824 18.5834,42.6384 17.3894,43.6164 C16.7084,44.1724 16.0864,44.9244 15.4284,45.7194 C14.7124,46.5834 13.9724,47.4784 13.1124,48.1594 C12.6264,48.5434 12.0804,48.9364 11.5524,49.2974 C13.1044,48.6704 15.1944,47.6644 17.4364,46.1264 C17.5024,46.0554 20.1474,43.4774 20.8204,42.9384 C21.0904,42.7224 21.3684,42.5654 21.6364,42.4714 C21.4404,42.3554 21.2214,42.2614 20.9824,42.2224 C20.8234,42.1954 20.6644,42.1824 20.5034,42.1824 M8.9824,50.8824 C8.8424,50.8824 8.7124,50.7984 8.6574,50.6634 C8.5944,50.5034 8.6554,50.3214 8.8034,50.2324 C8.8264,50.2184 11.1114,48.8494 12.6784,47.6104 C13.4804,46.9754 14.1954,46.1104 14.8884,45.2734 C15.5394,44.4874 16.2124,43.6734 16.9454,43.0754 C18.5144,41.7914 19.7944,41.3184 21.0974,41.5314 C21.9404,41.6724 22.5664,42.2604 22.8924,42.6404 C22.9654,42.7074 23.0294,42.7864 23.0844,42.8774 C23.1814,43.0384 23.1614,43.2924 23.0054,43.3964 C22.8454,43.4984 22.6284,43.4514 22.5214,43.2984 C22.4984,43.2674 22.4584,43.2124 22.4004,43.1434 C22.3574,43.1084 22.3144,43.0894 22.2594,43.0794 C22.0074,43.0394 21.6254,43.1914 21.2584,43.4854 C20.6104,44.0024 17.9054,46.6394 17.8784,46.6664 C13.1584,49.9104 9.2264,50.8364 9.0614,50.8734 C9.0344,50.8794 9.0084,50.8824 8.9824,50.8824" id="Fill-71" fill="#023A25"/>
          <path d="M8.9556,50.905 C8.7986,50.905 8.6556,50.8 8.6156,50.641 C8.5686,50.454 8.6836,50.263 8.8696,50.216 C9.1146,50.111 11.0476,48.631 11.8826,47.539 C12.3456,46.933 12.7686,46.11 13.2166,45.238 C13.5726,44.546 13.9406,43.83 14.3376,43.221 C15.2356,41.839 16.9966,40.399 18.6206,40.325 C20.2006,40.25 21.5346,41.612 21.5896,41.67 C21.7216,41.81 21.7156,42.031 21.5746,42.164 C21.4346,42.297 21.2116,42.29 21.0806,42.151 C21.0696,42.139 19.9266,40.99 18.6516,41.024 C17.2786,41.088 15.7066,42.4 14.9236,43.602 C14.5476,44.181 14.1866,44.882 13.8396,45.558 C13.3966,46.419 12.9386,47.308 12.4386,47.965 C11.5646,49.106 9.4346,50.796 9.0416,50.895 C9.0126,50.902 8.9836,50.905 8.9556,50.905" id="Fill-73" fill="#023A25"/>
          <path d="M24.8442,42.0925 C24.7102,42.0925 24.5822,42.0155 24.5252,41.8855 C24.5002,41.8305 22.0302,36.3535 18.2822,33.3365 C14.0102,29.8965 12.2602,29.9985 12.2482,29.9945 C12.0562,30.0035 11.8882,29.8725 11.8672,29.6835 C11.8482,29.4935 11.9802,29.3235 12.1682,29.2995 C12.3592,29.2825 14.2092,29.1565 18.7222,32.7915 C22.6022,35.9155 25.0612,41.3695 25.1632,41.6005 C25.2422,41.7775 25.1632,41.9845 24.9872,42.0625 C24.9412,42.0825 24.8922,42.0925 24.8442,42.0925" id="Fill-75" fill="#023A25"/>
          <path d="M12.0395,29.2771 C11.5275,29.2771 11.0215,29.3491 10.4275,29.5031 C9.5895,29.7191 9.1865,30.1141 8.9945,30.4411 C9.3995,30.3041 9.9415,30.1061 10.4315,29.8741 C10.9775,29.6161 11.6295,29.4231 12.2485,29.2811 C12.1795,29.2791 12.1085,29.2771 12.0395,29.2771 M23.4485,39.0581 C23.3835,39.0581 23.3165,39.0401 23.2585,39.0021 C23.0965,38.8961 23.0515,38.6791 23.1555,38.5171 C23.2015,38.4471 24.2665,36.7531 22.9255,34.9281 C21.6675,33.2171 18.3105,30.7121 15.8665,30.0451 C15.2835,29.8851 14.7805,29.7491 14.3325,29.6351 C13.6945,29.7151 11.9125,29.9471 10.7305,30.5061 C9.7485,30.9721 8.6105,31.2981 8.5625,31.3121 C8.4595,31.3401 8.3445,31.3221 8.2565,31.2551 C8.1685,31.1881 8.1175,31.0851 8.1175,30.9751 C8.1175,30.9101 8.1415,29.3701 10.2515,28.8251 C12.2085,28.3211 13.3975,28.6461 16.0095,29.3581 C18.8845,30.1431 22.2935,32.8871 23.4895,34.5141 C25.1245,36.7381 23.7555,38.8801 23.7415,38.9011 C23.6745,39.0031 23.5625,39.0581 23.4485,39.0581" id="Fill-77" fill="#023A25"/>
          <path d="M11.7036,30.2849 C11.7036,30.2849 11.7036,30.2859 11.7026,30.2859 C11.7036,30.2859 11.7036,30.2849 11.7036,30.2849 M21.6146,39.8009 C20.0026,39.8009 17.2786,37.5539 16.5626,36.3499 C14.7266,33.2589 12.1526,30.5919 11.5666,30.3499 C11.4366,30.3789 11.2996,30.3359 11.2126,30.2249 C11.0936,30.0739 11.1196,29.8529 11.2716,29.7339 C11.3316,29.6869 11.4596,29.6139 11.6586,29.6489 C12.5726,29.8209 15.5066,33.1999 17.1646,35.9929 C17.8616,37.1659 20.6316,39.3129 21.7556,39.0889 C22.7356,38.9109 22.7976,38.5119 22.7976,38.5079 C22.7976,38.3139 22.9536,38.1679 23.1466,38.1679 C23.3406,38.1679 23.4966,38.3339 23.4966,38.5269 C23.4966,38.6249 23.4516,39.4929 21.8806,39.7779 C21.7956,39.7929 21.7066,39.8009 21.6146,39.8009" id="Fill-79" fill="#023A25"/>
          <path d="M28.3423,33.6941 L28.3513,33.6941 L28.3423,33.6941 Z M30.1613,32.8491 C30.0873,32.8491 30.0143,32.8551 29.9413,32.8671 C29.4673,32.9441 29.0293,33.2871 28.6353,33.8861 C28.5923,33.9521 28.5283,34.0011 28.4533,34.0261 C28.4483,34.0271 27.9663,34.1931 27.6033,34.5041 C27.5113,34.5831 27.3793,34.8791 27.3743,35.2881 C27.3713,35.6381 27.4593,36.3101 28.0923,36.8771 C28.5803,37.3131 29.5063,37.9171 30.4873,38.5551 C31.7783,39.3961 33.1133,40.2651 33.8453,40.9991 C34.9113,42.0641 35.7023,43.0231 36.2343,43.8941 C36.0443,42.4181 35.6523,40.2841 35.0863,38.9021 C33.9963,36.2391 33.1913,34.6261 32.0523,33.7271 C31.5623,33.3401 30.8543,32.8491 30.1613,32.8491 Z M36.6593,45.9891 C36.6373,45.9891 36.6153,45.9871 36.5943,45.9821 C36.3503,45.9331 36.2773,45.6921 36.2233,45.5151 C35.8893,44.4191 34.9223,43.0651 33.3513,41.4931 C32.6693,40.8121 31.3073,39.9241 30.1053,39.1421 C29.1023,38.4881 28.1543,37.8711 27.6253,37.3981 C26.7863,36.6481 26.6693,35.7491 26.6753,35.2801 C26.6813,34.7181 26.8623,34.2161 27.1483,33.9721 C27.4983,33.6721 27.9153,33.4851 28.1143,33.4061 C28.6063,32.6941 29.1823,32.2811 29.8283,32.1761 C30.8553,32.0071 31.8393,32.6681 32.4863,33.1781 C33.7453,34.1731 34.6003,35.8641 35.7353,38.6361 C36.5333,40.5911 37.3393,45.3811 36.9483,45.8551 C36.8803,45.9381 36.7683,45.9891 36.6593,45.9891 Z" id="Fill-81" fill="#023A25"/>
          <path d="M24.188,46.613 C24.102,46.613 24.017,46.582 23.949,46.519 C23.808,46.387 23.8,46.166 23.932,46.024 C24.027,45.924 26.274,43.527 28.815,41.859 C30.926,40.474 32.777,40.357 32.854,40.352 C33.087,40.365 33.212,40.49 33.222,40.681 C33.234,40.875 33.086,41.04 32.894,41.051 C32.876,41.052 31.144,41.169 29.2,42.445 C26.73,44.065 24.466,46.478 24.444,46.503 C24.375,46.576 24.282,46.613 24.188,46.613" id="Fill-83" fill="#023A25"/>
          <path d="M27.0376,47.1433 C26.6996,47.1433 26.4006,47.0703 26.1606,46.9173 C25.4106,46.4403 25.0286,45.2873 24.9876,45.1573 C24.9306,44.9723 25.0316,44.7763 25.2166,44.7183 C25.3996,44.6563 25.5966,44.7603 25.6556,44.9463 C25.7466,45.2323 26.0826,46.0393 26.5366,46.3283 C27.0366,46.6463 28.3356,46.3303 29.5636,45.2703 C29.9116,44.9703 30.3296,44.5863 30.7666,44.1853 C32.6246,42.4833 33.5576,41.6813 34.0816,41.6813 C34.2746,41.6813 34.4316,41.8373 34.4316,42.0313 C34.4316,42.2243 34.2746,42.3803 34.0816,42.3803 C33.7126,42.4363 32.1646,43.8543 31.2406,44.7013 C30.7966,45.1083 30.3736,45.4963 30.0206,45.7993 C29.0266,46.6593 27.9056,47.1433 27.0376,47.1433" id="Fill-85" fill="#023A25"/>
          <path d="M25.2007,45.1599 C25.0677,45.1599 24.9407,45.0839 24.8827,44.9549 C24.8667,44.9219 24.5147,44.1299 24.8877,43.3859 C25.2077,42.7459 26.8877,40.8499 28.6877,40.0789 C30.4507,39.3219 31.7227,39.5009 31.7767,39.5109 C31.9677,39.5389 32.0977,39.7159 32.0707,39.9069 C32.0437,40.0979 31.8627,40.2269 31.6747,40.2029 C31.6637,40.1999 30.5287,40.0509 28.9637,40.7219 C27.3267,41.4229 25.7597,43.2079 25.5127,43.6979 C25.2927,44.1399 25.5167,44.6609 25.5187,44.6659 C25.5987,44.8419 25.5207,45.0499 25.3457,45.1289 C25.2987,45.1499 25.2497,45.1599 25.2007,45.1599" id="Fill-87" fill="#023A25"/>
          <path d="M39.9292,42.4129 L39.9382,42.4129 L39.9292,42.4129 Z M40.2442,42.8639 C40.2132,42.8639 40.1812,42.8599 40.1512,42.8509 C40.0582,42.8259 39.9802,42.7639 39.9352,42.6799 C39.9122,42.6389 39.3822,41.6789 38.1322,41.4699 C36.7982,41.2489 36.1702,41.4009 36.1632,41.4029 C35.9772,41.4439 35.7862,41.3389 35.7382,41.1529 C35.6892,40.9669 35.7972,40.7769 35.9832,40.7259 C36.0142,40.7189 36.7362,40.5289 38.2472,40.7789 C38.4512,40.8129 38.6412,40.8639 38.8152,40.9259 C37.6712,39.9819 35.7002,39.7839 35.6792,39.7819 C35.4872,39.7639 35.3452,39.5929 35.3642,39.3999 C35.3822,39.2069 35.5352,39.0609 35.7452,39.0849 C35.8552,39.0959 38.4372,39.3569 39.6622,40.7759 C40.5862,41.8459 40.6602,42.2609 40.6222,42.5019 C40.6062,42.6039 40.5122,42.7619 40.4242,42.8139 C40.3692,42.8479 40.3072,42.8639 40.2442,42.8639 Z" id="Fill-89" fill="#023A25"/>
          <path d="M39.1557,41.5955 C39.0787,41.5955 39.0017,41.5715 38.9367,41.5195 C37.8377,40.6395 35.9727,40.6285 35.9547,40.6285 C35.7607,40.6285 35.6047,40.4725 35.6047,40.2795 C35.6047,40.0855 35.7607,39.9295 35.9547,39.9295 C36.0407,39.9295 38.0857,39.9415 39.3747,40.9725 C39.5247,41.0935 39.5507,41.3135 39.4287,41.4645 C39.3597,41.5505 39.2587,41.5955 39.1557,41.5955" id="Fill-91" fill="#023A25"/>
          <path d="M13.9907,15.4509 L19.0747,22.0629 L24.0457,28.0789 C24.7637,26.9039 23.6617,25.4139 22.5947,24.2279 L22.4617,24.0789 C21.6307,23.1349 18.8047,20.0009 16.8027,18.0569 C16.1587,17.4339 15.0547,16.4189 13.9907,15.4509 M25.8367,31.1449 C25.7357,31.1449 25.6367,31.1019 25.5667,31.0179 L18.5277,22.4989 L11.0897,12.8239 C10.9787,12.6809 10.9967,12.4769 11.1287,12.3539 C11.2607,12.2329 11.4647,12.2289 11.5997,12.3499 C11.6407,12.3879 15.7577,16.0689 17.2897,17.5549 C19.3087,19.5129 22.1507,22.6659 22.9867,23.6159 L23.1147,23.7599 C23.8767,24.6059 25.8797,26.8339 24.5097,28.6389 L26.1057,30.5729 C26.2287,30.7219 26.2087,30.9419 26.0587,31.0649 C25.9937,31.1189 25.9157,31.1449 25.8367,31.1449" id="Fill-93" fill="#023A25"/>
          <path d="M22.2407,29.5134 C21.1857,29.5134 20.1657,28.8824 20.1227,28.8564 C19.9587,28.7534 19.9087,28.5374 20.0117,28.3734 C20.1127,28.2114 20.3267,28.1584 20.4937,28.2624 C20.5027,28.2684 21.3887,28.8144 22.2407,28.8144 C23.1087,28.8144 23.8197,28.4364 23.8267,28.4324 C23.9947,28.3414 24.2087,28.4044 24.3017,28.5754 C24.3917,28.7444 24.3287,28.9574 24.1587,29.0494 C24.1237,29.0684 23.2837,29.5134 22.2407,29.5134" id="Fill-95" fill="#023A25"/>
          <path d="M17.6557,26.3582 C17.5547,26.3582 17.4547,26.3152 17.3847,26.2312 C17.3277,26.1612 15.9507,24.4572 14.5557,19.9112 C13.4447,16.2872 12.0787,14.2942 11.4227,13.3372 C11.2567,13.0942 11.1317,12.9112 11.0587,12.7792 C10.9677,12.6092 11.0297,12.3962 11.1987,12.3032 C11.3707,12.2122 11.5817,12.2742 11.6747,12.4432 C11.7387,12.5612 11.8507,12.7242 11.9997,12.9402 C12.6757,13.9262 14.0827,15.9792 15.2257,19.7062 C16.5717,24.0942 17.9127,25.7702 17.9267,25.7872 C18.0487,25.9362 18.0267,26.1572 17.8777,26.2792 C17.8117,26.3332 17.7337,26.3582 17.6557,26.3582" id="Fill-97" fill="#023A25"/>
          <path d="M36.5581,45.281 C36.3901,45.281 36.2431,45.161 36.2131,44.991 C35.9391,43.396 34.0091,40.469 32.4591,38.118 L32.2191,37.753 C30.7981,35.597 28.6471,34.276 28.2411,34.04 C27.9551,34.029 26.8871,34.042 26.0021,34.648 C25.8461,34.757 25.6261,34.718 25.5161,34.556 C25.4071,34.397 25.4481,34.179 25.6081,34.07 C26.8441,33.224 28.3071,33.342 28.3721,33.345 C28.4201,33.349 28.4681,33.364 28.5101,33.387 C28.6171,33.446 31.1431,34.847 32.8031,37.368 L33.0431,37.732 C34.7121,40.263 36.6031,43.133 36.9031,44.872 C36.9361,45.062 36.8081,45.244 36.6181,45.276 C36.5971,45.28 36.5781,45.281 36.5581,45.281" id="Fill-99" fill="#023A25"/>
          <path d="M20.2475,57.4529 C20.1795,57.4529 20.1095,57.4389 20.0435,57.4099 C19.7915,57.2969 19.6785,57.0019 19.7905,56.7489 C19.8255,56.6709 23.3065,48.8279 24.1635,43.2239 C24.4255,41.5059 25.1845,36.1009 25.1845,35.2659 C25.1845,34.3499 25.7655,26.7309 25.7895,26.4069 C25.8025,26.2429 25.6055,24.8849 25.7365,24.8039 C25.8255,24.7489 25.7985,24.4499 25.8995,24.4549 C26.0185,24.4639 26.6095,24.4109 26.6385,24.5179 C26.6855,24.6899 26.8215,25.6589 26.7585,26.5059 C26.5565,29.2369 26.1845,34.5629 26.1845,35.2659 C26.1845,36.2639 25.3345,42.1819 25.1515,43.3749 C24.2735,49.1129 20.8495,56.8309 20.7045,57.1569 C20.6215,57.3429 20.4385,57.4529 20.2475,57.4529" id="Fill-101" fill="#023A25"/>
          <path d="M19.7593,30.7976 C19.6993,30.7976 19.6403,30.7926 19.5823,30.7826 C18.9713,30.6816 18.5663,29.7766 18.4903,29.5976 C18.4163,29.4186 18.5003,29.2146 18.6793,29.1406 C18.8573,29.0666 19.0623,29.1506 19.1373,29.3276 C19.2923,29.6986 19.5653,30.0706 19.6973,30.0926 C19.8283,30.1176 20.0543,30.0566 20.1703,29.9176 C20.2403,29.8336 20.2533,29.7376 20.2103,29.6156 C20.0453,29.1506 19.9163,29.0266 19.9153,29.0266 C19.7493,28.9266 19.7123,28.7226 19.8123,28.5566 C19.9133,28.3896 20.1453,28.3496 20.3083,28.4486 C20.4183,28.5156 20.6383,28.7256 20.8703,29.3826 C20.9963,29.7406 20.9393,30.0916 20.7063,30.3676 C20.4833,30.6346 20.1133,30.7976 19.7593,30.7976" id="Fill-103" fill="#023A25"/>
          <path d="M25.4429,36.8533 C25.2499,36.8533 25.0929,36.6973 25.0929,36.5033 C25.0929,36.1303 24.2039,34.4053 23.1289,33.2033 C22.1419,32.1013 20.1009,30.7053 20.0809,30.6913 C19.9219,30.5823 19.8799,30.3653 19.9889,30.2053 C20.0969,30.0433 20.3159,30.0033 20.4749,30.1133 C20.5609,30.1713 22.6059,31.5703 23.6499,32.7363 C24.5939,33.7913 25.7929,35.7783 25.7929,36.5033 C25.7929,36.6973 25.6359,36.8533 25.4429,36.8533" id="Fill-105" fill="#023A25"/>
          <path d="M17.8345,28.6638 C17.8915,28.7158 17.9475,28.7628 18.0015,28.7998 C18.2905,29.0038 19.0725,29.1508 19.4185,28.9388 C19.7985,28.7058 20.0495,28.0618 19.9275,27.8168 C19.8635,27.6888 19.8005,27.5438 19.7415,27.3978 L19.5045,27.8888 C19.4215,28.0608 19.2205,28.1348 19.0435,28.0548 L18.6395,27.8698 L18.5595,28.3908 C18.5315,28.5678 18.3675,28.6708 18.1915,28.6858 L17.8345,28.6638 Z M18.9355,29.7418 C18.4225,29.7418 17.8915,29.5778 17.5995,29.3738 C17.1825,29.0808 16.7485,28.4938 16.7305,28.4688 C16.6495,28.3588 16.6405,28.2128 16.7045,28.0938 C16.7705,27.9758 16.8945,27.8988 17.0335,27.9118 L17.9165,27.9678 L18.0185,27.3068 C18.0345,27.1988 18.1015,27.1038 18.1975,27.0528 C18.2935,26.9988 18.4075,26.9948 18.5095,27.0418 L19.0225,27.2758 L19.4745,26.3448 C19.5375,26.2148 19.6735,26.1418 19.8145,26.1478 C19.9575,26.1578 20.0795,26.2548 20.1235,26.3918 C20.1255,26.3978 20.3435,27.0838 20.5545,27.5048 C20.8745,28.1468 20.3985,29.1578 19.7855,29.5348 C19.5465,29.6828 19.2445,29.7418 18.9355,29.7418 Z" id="Fill-107" fill="#023A25"/>
          <path d="M17.312,28.4236 C17.174,28.4236 17.044,28.3426 16.989,28.2066 C16.925,28.0546 16.395,26.6946 17.103,25.9866 C17.762,25.3286 18.356,25.4886 18.676,25.5756 C19.152,25.6946 19.651,26.2516 19.793,26.4206 C19.918,26.5686 19.901,26.7886 19.753,26.9136 C19.608,27.0386 19.386,27.0236 19.26,26.8736 C19.017,26.5876 18.692,26.3026 18.58,26.2746 C18.577,26.2736 18.496,26.2516 18.494,26.2516 C18.224,26.1786 17.969,26.1106 17.597,26.4816 C17.296,26.7826 17.494,27.5946 17.635,27.9416 C17.709,28.1196 17.624,28.3236 17.445,28.3976 C17.402,28.4156 17.357,28.4236 17.312,28.4236" id="Fill-109" fill="#023A25"/>
          <path d="M29.3345,31.8611 C29.1195,31.8611 28.9175,31.7881 28.7435,31.6431 C28.4445,31.3951 28.2795,30.9541 28.3415,30.5711 C28.4355,30.0011 29.2235,29.6271 29.4635,29.5261 C29.6375,29.4531 29.8455,29.5341 29.9215,29.7121 C29.9965,29.8891 29.9135,30.0951 29.7355,30.1711 C29.3995,30.3131 29.0515,30.5671 29.0325,30.6831 C29.0125,30.8061 29.0715,31.0061 29.1915,31.1051 C29.2645,31.1661 29.3465,31.1741 29.4525,31.1381 C29.8815,30.9851 29.9945,30.8671 29.9955,30.8651 C30.0945,30.6991 30.2975,30.6611 30.4635,30.7601 C30.6295,30.8591 30.6725,31.0901 30.5735,31.2571 C30.5115,31.3631 30.3125,31.5741 29.6885,31.7971 C29.5685,31.8401 29.4495,31.8611 29.3345,31.8611" id="Fill-111" fill="#023A25"/>
          <path d="M30.3188,28.989 C30.2868,29.026 30.2578,29.062 30.2338,29.097 C30.0478,29.364 29.9198,30.095 30.1098,30.402 C30.3208,30.739 30.9198,30.977 31.1348,30.866 C31.2418,30.812 31.3618,30.759 31.4828,30.709 L31.0678,30.51 C30.8968,30.427 30.8218,30.224 30.8998,30.051 L31.0588,29.696 L30.5998,29.627 C30.4218,29.6 30.2918,29.441 30.3018,29.26 L30.3188,28.989 Z M30.9978,31.592 C30.4388,31.592 29.7968,31.222 29.5158,30.772 C29.1418,30.17 29.3508,29.141 29.6598,28.697 C29.9288,28.308 30.4518,27.917 30.5098,27.874 C30.6208,27.792 30.7648,27.783 30.8848,27.847 C31.0048,27.912 31.0758,28.04 31.0678,28.176 L31.0198,28.982 L31.6198,29.072 C31.7278,29.088 31.8228,29.154 31.8748,29.25 C31.9268,29.346 31.9318,29.461 31.8868,29.561 L31.6778,30.026 L32.5338,30.436 C32.6628,30.498 32.7418,30.633 32.7318,30.775 C32.7218,30.919 32.6258,31.041 32.4888,31.085 C32.4828,31.087 31.8428,31.293 31.4508,31.491 C31.3138,31.56 31.1588,31.592 30.9978,31.592 Z" id="Fill-113" fill="#023A25"/>
          <path d="M32.4302,30.8836 C32.3312,30.8836 32.2342,30.8426 32.1642,30.7626 C32.0382,30.6156 32.0552,30.3946 32.2012,30.2686 C32.4632,30.0436 32.7272,29.7426 32.7552,29.6376 C32.8442,29.3006 32.9042,29.0776 32.5622,28.7396 C32.2892,28.4676 31.5382,28.6546 31.2182,28.7856 C31.0362,28.8626 30.8342,28.7746 30.7612,28.5966 C30.6882,28.4176 30.7722,28.2136 30.9502,28.1396 C31.0952,28.0796 32.3792,27.5726 33.0542,28.2426 C33.6842,28.8656 33.5342,29.4326 33.4522,29.7376 C33.3402,30.1896 32.8172,30.6636 32.6592,30.7996 C32.5922,30.8566 32.5112,30.8836 32.4302,30.8836" id="Fill-115" fill="#023A25"/>
          <path d="M28.4526,28.3201 C28.3166,28.3201 28.1816,28.2891 28.0496,28.2251 C27.6986,28.0581 27.4306,27.6721 27.3966,27.2861 C27.3476,26.7111 28.0196,26.1551 28.2266,25.9991 C28.3816,25.8821 28.5996,25.9141 28.7176,26.0671 C28.8336,26.2201 28.8036,26.4401 28.6486,26.5571 C28.3586,26.7771 28.0846,27.1081 28.0946,27.2251 C28.1046,27.3491 28.2106,27.5271 28.3506,27.5941 C28.4366,27.6341 28.5176,27.6251 28.6116,27.5621 C28.9876,27.3091 29.0686,27.1671 29.0696,27.1661 C29.1236,26.9801 29.3106,26.8931 29.4966,26.9471 C29.6816,27.0021 29.7816,27.2151 29.7266,27.4001 C29.6916,27.5201 29.5516,27.7751 29.0016,28.1431 C28.8276,28.2601 28.6396,28.3201 28.4526,28.3201" id="Fill-117" fill="#023A25"/>
          <path d="M28.8159,25.6736 C28.7929,26.0236 28.8719,26.4976 29.0689,26.6886 C29.3549,26.9636 29.9919,27.0426 30.1749,26.8866 C30.2649,26.8086 30.3679,26.7276 30.4719,26.6496 L30.0219,26.5576 C29.8789,26.5286 29.7689,26.4146 29.7459,26.2716 L29.6909,25.9336 L29.1309,25.9086 C28.9849,25.9026 28.8619,25.8066 28.8159,25.6736 M29.8509,27.6666 C29.3869,27.6666 28.8829,27.4816 28.5839,27.1926 C28.0739,26.7006 28.0239,25.6536 28.2119,25.1496 C28.3789,24.7056 28.7889,24.1986 28.8359,24.1426 C28.9269,24.0286 29.0799,23.9876 29.2179,24.0316 C29.3559,24.0786 29.4509,24.2066 29.4559,24.3526 L29.4859,25.2246 L30.0059,25.2466 C30.1709,25.2536 30.3089,25.3766 30.3369,25.5396 L30.3979,25.9196 L31.4229,26.1276 C31.5639,26.1556 31.6729,26.2676 31.6989,26.4076 C31.7239,26.5496 31.6609,26.6916 31.5399,26.7676 C31.5339,26.7716 30.9639,27.1266 30.6339,27.4136 C30.4329,27.5896 30.1499,27.6666 29.8509,27.6666" id="Fill-119" fill="#023A25"/>
          <path d="M25.937,32.4705 C25.858,32.4705 25.819,32.4525 25.782,32.4365 C25.605,32.3585 25.526,32.1515 25.605,31.9745 C25.666,31.8345 25.821,31.7505 25.952,31.7685 C26.001,31.7615 26.214,31.7205 26.827,31.4885 C27.716,31.1495 28.504,30.7545 28.512,30.7505 C28.685,30.6635 28.895,30.7335 28.982,30.9065 C29.069,31.0785 29,31.2885 28.827,31.3755 C28.793,31.3925 28,31.7905 27.075,32.1425 C26.382,32.4065 26.087,32.4705 25.937,32.4705" id="Fill-121" fill="#023A25"/>
          <path d="M26.1235,29.533 C25.9755,29.533 25.8375,29.438 25.7895,29.289 C25.7315,29.104 25.8335,28.908 26.0185,28.849 C26.4285,28.721 27.1305,28.032 27.4765,27.63 C27.6025,27.482 27.8235,27.466 27.9695,27.592 C28.1165,27.718 28.1335,27.938 28.0075,28.085 C27.9025,28.208 26.9615,29.286 26.2285,29.517 C26.1935,29.528 26.1585,29.533 26.1235,29.533" id="Fill-123" fill="#023A25"/>
          <path d="M24.355,46.0662 C24.191,46.0662 24.045,45.9522 24.012,45.7852 C24.011,45.7852 23.936,45.4802 23.499,44.6042 C23.063,43.7322 22.574,43.3372 22.569,43.3342 C22.418,43.2132 22.391,42.9942 22.51,42.8422 C22.629,42.6912 22.848,42.6642 23,42.7822 C23.024,42.8012 23.612,43.2652 24.126,44.2922 C24.604,45.2512 24.687,45.5892 24.698,45.6482 C24.736,45.8372 24.613,46.0222 24.423,46.0592 C24.401,46.0642 24.377,46.0662 24.355,46.0662" id="Fill-125" fill="#023A25"/>
          <path d="M31.2222,26.8211 C31.1092,26.8211 30.9972,26.7661 30.9302,26.6641 C30.8242,26.5031 30.8682,26.2861 31.0302,26.1791 C31.4862,25.8791 31.4472,25.7741 31.3542,25.5211 C31.3302,25.4601 31.3092,25.3981 31.2902,25.3371 C31.2062,25.0471 31.0652,24.5591 30.1342,24.4081 C29.5702,24.3151 29.4382,24.4251 29.4372,24.4271 C29.4492,24.6211 29.3102,24.7441 29.1182,24.7561 C28.9142,24.7441 28.7672,24.5781 28.7562,24.3841 C28.7482,24.2541 28.8002,24.0591 28.9692,23.9081 C29.2212,23.6801 29.6412,23.6171 30.2462,23.7171 C31.6142,23.9391 31.8682,24.8131 31.9632,25.1421 C31.9772,25.1891 31.9932,25.2341 32.0102,25.2791 C32.1672,25.7041 32.2632,26.2041 31.4142,26.7631 C31.3562,26.8021 31.2882,26.8211 31.2222,26.8211" id="Fill-127" fill="#023A25"/>
          <path d="M33.8569,26.3757 L33.8549,26.3757 L31.8309,26.3637 C31.6369,26.3627 31.4809,26.2057 31.4829,26.0127 C31.4839,25.8177 31.6439,25.6687 31.8349,25.6647 L33.8589,25.6767 C34.0519,25.6777 34.2089,25.8347 34.2069,26.0277 C34.2059,26.2207 34.0489,26.3757 33.8569,26.3757" id="Fill-129" fill="#023A25"/>
          <path d="M33.4663,27.4675 C33.4273,27.4675 33.3873,27.4615 33.3483,27.4465 L31.5293,26.7955 C31.3473,26.7315 31.2523,26.5305 31.3183,26.3485 C31.3823,26.1665 31.5813,26.0685 31.7653,26.1375 L33.5843,26.7885 C33.7663,26.8535 33.8613,27.0535 33.7953,27.2365 C33.7443,27.3785 33.6103,27.4675 33.4663,27.4675" id="Fill-131" fill="#023A25"/>
          <path d="M34.548,25.6998 C34.42,25.6868 34.306,25.7798 34.294,25.9068 C34.281,26.0348 34.375,26.1478 34.502,26.1608 C34.63,26.1728 34.744,26.0798 34.756,25.9528 C34.769,25.8258 34.676,25.7128 34.548,25.6998" id="Fill-133" fill="#023A25"/>
          <path d="M34.7292,26.7268 C34.6012,26.7138 34.4872,26.8068 34.4752,26.9338 C34.4622,27.0618 34.5562,27.1748 34.6832,27.1878 C34.8112,27.1998 34.9252,27.1068 34.9372,26.9798 C34.9502,26.8528 34.8572,26.7398 34.7292,26.7268" id="Fill-135" fill="#023A25"/>
          <path d="M34.9709,26.1227 C34.8429,26.1097 34.7289,26.2027 34.7169,26.3297 C34.7039,26.4577 34.7979,26.5707 34.9249,26.5837 C35.0529,26.5957 35.1669,26.5027 35.1789,26.3757 C35.1919,26.2487 35.0989,26.1357 34.9709,26.1227" id="Fill-137" fill="#023A25"/>
          <path d="M34.1553,27.1195 C34.0273,27.1065 33.9133,27.1995 33.9013,27.3265 C33.8883,27.4545 33.9823,27.5675 34.1093,27.5805 C34.2373,27.5925 34.3513,27.4995 34.3633,27.3725 C34.3763,27.2455 34.2833,27.1325 34.1553,27.1195" id="Fill-139" fill="#023A25"/>
          <path d="M34.1855,26.3945 C34.0575,26.3815 33.9435,26.4745 33.9315,26.6015 C33.9185,26.7295 34.0125,26.8425 34.1395,26.8555 C34.2675,26.8675 34.3815,26.7745 34.3935,26.6475 C34.4065,26.5205 34.3135,26.4075 34.1855,26.3945" id="Fill-141" fill="#023A25"/>
          <path d="M23.145,1 C10.934,1 1,15.645 1,33.646 C1,51.646 10.934,66.291 23.145,66.291 C35.356,66.291 45.291,51.646 45.291,33.646 C45.291,15.645 35.356,1 23.145,1 M23.145,67.291 C10.383,67.291 0,52.197 0,33.646 C0,15.094 10.383,0 23.145,0 C35.908,0 46.291,15.094 46.291,33.646 C46.291,52.197 35.908,67.291 23.145,67.291" id="Fill-143" fill="#023A25"/>
        </g>
      </svg>
    )
  }
}

export default IconGarden;
