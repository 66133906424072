import React, { Component } from 'react';

class IconGelato extends Component {
  render() {
    return (
      <svg width="47px" height="68px" viewBox="0 0 47 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>gelato</title>
        <g id="gelato" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M17.0705,33.404 C17.0705,33.404 17.0705,33.405 17.0715,33.406 C17.0705,33.405 17.0705,33.404 17.0705,33.404 Z M17.0715,33.406 C17.0715,33.406 17.0715,33.407 17.0725,33.407 C17.0715,33.407 17.0715,33.406 17.0715,33.406 Z M17.0725,33.408 L17.0735,33.409 L17.0725,33.408 Z M17.0735,33.41 C17.0745,33.41 17.0745,33.41 17.0745,33.411 C17.0745,33.41 17.0745,33.41 17.0735,33.41 Z M33.1005,35.909 C31.7165,35.909 30.5015,34.769 30.2565,34.524 C29.9125,34.18 29.2505,34.013 29.0245,33.978 C28.7515,33.936 28.5645,33.68 28.6065,33.408 C28.6485,33.135 28.9035,32.952 29.1745,32.99 C29.2905,33.008 30.3255,33.179 30.9635,33.817 C31.4215,34.275 32.4285,35.015 33.3005,34.895 C33.7045,34.839 33.9725,34.717 34.0975,34.537 C34.3535,34.168 34.1765,33.381 33.9885,32.549 C33.7755,31.597 33.5325,30.52 33.7865,29.508 C34.1575,28.02 34.8365,23.187 34.2345,20.604 C33.7125,18.366 31.2195,11.18 21.1535,12.614 C12.2955,13.88 12.2365,22.315 12.6225,25.883 C12.7385,26.959 12.8665,27.763 12.9685,28.401 C13.1895,29.79 13.2995,30.487 12.8725,31.414 C12.4895,32.242 12.0625,33.781 12.8845,34.296 C13.8105,34.874 15.0125,34.005 15.8905,33.37 L16.1065,33.214 C16.7785,32.733 17.2125,32.559 17.5415,32.624 C17.6765,32.651 17.8475,32.759 17.9205,32.877 C18.0655,33.111 17.9935,33.42 17.7585,33.565 C17.6375,33.64 17.4965,33.657 17.3685,33.624 C17.2815,33.655 17.0765,33.75 16.6875,34.028 L16.4775,34.179 C15.4325,34.937 13.8555,36.081 12.3555,35.143 C11.0355,34.319 11.2875,32.46 11.9645,30.994 C12.2645,30.343 12.1875,29.857 11.9805,28.557 C11.8775,27.908 11.7465,27.087 11.6285,25.991 C11.3025,22.982 11.0175,13.052 21.0115,11.624 C32.2115,10.021 34.8005,18.634 35.2085,20.378 C35.8585,23.164 35.1575,28.143 34.7565,29.75 C34.5605,30.533 34.7655,31.446 34.9645,32.329 C35.2015,33.382 35.4245,34.376 34.9205,35.105 C34.6265,35.531 34.1415,35.787 33.4405,35.885 C33.3265,35.901 33.2135,35.909 33.1005,35.909 Z" id="Fill-145" fill="#023A25"/>
          <path d="M20.5729,36.6355 C19.4969,36.6355 18.7299,36.0755 18.3879,35.8265 C17.2819,35.0215 15.8289,34.2085 15.8139,34.2005 C15.6449,34.1075 15.5849,33.8935 15.6789,33.7255 C15.7729,33.5555 15.9849,33.4935 16.1549,33.5895 C16.2159,33.6235 17.6609,34.4305 18.7999,35.2605 C19.2409,35.5825 20.1619,36.2545 21.4949,35.7635 C21.5299,35.7455 21.6669,35.6375 21.7779,35.5505 C22.3079,35.1335 23.2949,34.3585 24.4829,34.3015 C24.6749,34.2875 24.8399,34.4415 24.8489,34.6345 C24.8579,34.8275 24.7089,34.9915 24.5159,35.0005 C23.5519,35.0465 22.7119,35.7065 22.2109,36.1015 C21.9929,36.2725 21.8619,36.3745 21.7359,36.4215 C21.3169,36.5745 20.9279,36.6355 20.5729,36.6355" id="Fill-147" fill="#023A25"/>
          <path d="M27.0358,36.6472 C27.0038,36.6472 26.9718,36.6472 26.9398,36.6462 C26.2098,36.6202 25.5748,36.2902 25.1508,35.7172 C24.0698,34.2602 23.3548,34.2452 23.3478,34.2452 L23.3468,34.2452 C23.1598,34.2452 22.9978,34.0972 22.9898,33.9102 C22.9818,33.7222 23.1148,33.5622 23.3018,33.5462 C23.4098,33.5302 24.3858,33.5112 25.7128,35.3002 C26.0908,35.8102 26.6018,35.9332 26.9648,35.9462 C27.5198,35.9612 28.1188,35.7352 28.4218,35.3812 C29.8108,33.7662 30.7318,34.1482 30.8318,34.1992 C31.0058,34.2842 31.0778,34.4932 30.9938,34.6672 C30.9088,34.8392 30.6988,34.9142 30.5258,34.8282 C30.5198,34.8292 29.9548,34.6732 28.9518,35.8382 C28.5238,36.3352 27.7808,36.6472 27.0358,36.6472" id="Fill-149" fill="#023A25"/>
          <path d="M25.3137,53.2038 C25.1717,53.2038 25.0387,53.1178 24.9857,52.9768 C23.7387,49.6648 22.7747,47.0928 21.8087,44.5128 C20.8607,41.9798 19.9097,39.4398 18.6847,36.1878 C18.6167,36.0078 18.7087,35.8058 18.8887,35.7378 C19.0727,35.6688 19.2717,35.7608 19.3397,35.9418 C20.5647,39.1938 21.5157,41.7338 22.4647,44.2668 C23.4307,46.8468 24.3937,49.4188 25.6417,52.7308 C25.7097,52.9118 25.6177,53.1138 25.4377,53.1818 C25.3967,53.1968 25.3547,53.2038 25.3137,53.2038" id="Fill-151" fill="#023A25"/>
          <path d="M27.6994,47.2175 C27.5574,47.2175 27.4244,47.1315 27.3714,46.9905 C26.1354,43.7085 25.6444,42.3985 25.1534,41.0885 C24.6624,39.7785 24.1714,38.4685 22.9354,35.1865 C22.8674,35.0055 22.9584,34.8035 23.1394,34.7365 C23.3234,34.6655 23.5224,34.7585 23.5904,34.9405 C24.8264,38.2225 25.3174,39.5325 25.8084,40.8425 C26.3004,42.1535 26.7914,43.4625 28.0274,46.7445 C28.0954,46.9255 28.0034,47.1275 27.8234,47.1955 C27.7824,47.2105 27.7404,47.2175 27.6994,47.2175" id="Fill-153" fill="#023A25"/>
          <path d="M29.86,41.2761 C29.718,41.2761 29.585,41.1901 29.532,41.0491 C29.072,39.8271 28.873,39.2531 28.674,38.6771 C28.477,38.1071 28.279,37.5371 27.822,36.3231 C27.754,36.1421 27.845,35.9401 28.026,35.8731 C28.21,35.8031 28.409,35.8951 28.477,36.0771 C28.938,37.2991 29.136,37.8741 29.336,38.4491 C29.533,39.0191 29.731,39.5891 30.188,40.8031 C30.256,40.9841 30.164,41.1861 29.984,41.2541 C29.943,41.2691 29.901,41.2761 29.86,41.2761" id="Fill-155" fill="#023A25"/>
          <path d="M20.8576,53.0241 C20.8166,53.0241 20.7756,53.0171 20.7346,53.0031 C20.5536,52.9341 20.4616,52.7331 20.5296,52.5521 C21.6536,49.5451 22.7196,46.9071 23.7496,44.3561 C24.7786,41.8061 25.8436,39.1701 26.9666,36.1681 C27.0336,35.9851 27.2346,35.8961 27.4166,35.9621 C27.5976,36.0301 27.6896,36.2311 27.6216,36.4121 C26.4966,39.4231 25.4296,42.0641 24.3986,44.6191 C23.3696,47.1651 22.3066,49.7981 21.1856,52.7961 C21.1326,52.9381 20.9996,53.0241 20.8576,53.0241" id="Fill-157" fill="#023A25"/>
          <path d="M18.5178,47.4255 C18.4768,47.4255 18.4358,47.4185 18.3948,47.4045 C18.2138,47.3355 18.1218,47.1345 18.1898,46.9535 L22.6608,35.0095 C22.7278,34.8265 22.9278,34.7375 23.1108,34.8035 C23.2918,34.8715 23.3838,35.0725 23.3158,35.2535 L18.8458,47.1975 C18.7928,47.3395 18.6598,47.4255 18.5178,47.4255" id="Fill-159" fill="#023A25"/>
          <path d="M16.2834,41.1306 C16.2424,41.1306 16.2014,41.1236 16.1604,41.1096 C15.9794,41.0406 15.8874,40.8396 15.9554,40.6586 L17.8294,35.6276 C17.8964,35.4446 18.0974,35.3566 18.2794,35.4216 C18.4604,35.4896 18.5524,35.6916 18.4844,35.8716 L16.6114,40.9026 C16.5584,41.0446 16.4254,41.1306 16.2834,41.1306" id="Fill-161" fill="#023A25"/>
          <path d="M23.0671,57.6989 C23.0581,57.6989 23.0481,57.6989 23.0391,57.6979 C22.1211,57.6769 21.5791,56.5449 21.4801,56.3179 L13.5251,35.1699 C13.4281,34.9109 13.5591,34.6229 13.8171,34.5259 C14.0771,34.4269 14.3641,34.5599 14.4621,34.8179 L22.4061,55.9419 C22.5551,56.2749 22.8811,56.6949 23.0641,56.6989 L23.0661,56.6989 C23.1541,56.6989 23.3751,56.5519 23.6501,55.9959 L29.4521,40.7369 L31.6551,35.0879 C31.7551,34.8309 32.0461,34.7029 32.3021,34.8039 C32.5601,34.9039 32.6871,35.1939 32.5871,35.4509 L30.3851,41.0959 L24.5661,56.3959 C24.1311,57.2749 23.6331,57.6989 23.0671,57.6989" id="Fill-163" fill="#023A25"/>
          <path d="M23.4417,1.1365 C11.2307,1.1365 1.2957,15.7815 1.2957,33.7825 C1.2957,51.7825 11.2307,66.4275 23.4417,66.4275 C35.6527,66.4275 45.5877,51.7825 45.5877,33.7825 C45.5877,15.7815 35.6527,1.1365 23.4417,1.1365 M23.4417,67.4275 C10.6787,67.4275 0.2957,52.3335 0.2957,33.7825 C0.2957,15.2305 10.6787,0.1365 23.4417,0.1365 C36.2047,0.1365 46.5877,15.2305 46.5877,33.7825 C46.5877,52.3335 36.2047,67.4275 23.4417,67.4275" id="Fill-165" fill="#023A25"/>
        </g>
      </svg>
    )
  }
}

export default IconGelato;
