import React, { Component } from 'react';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
  @media( ${props => props.theme.breakpoints.md } ) {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100vh;
  }
`;

const ImageHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity 0.25s;
`;


class Gallery extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      intervalId: null,
      activeIndex: 0,
    }
  }

  componentDidMount() {
    if ( this.props.images.length > 1 ) {
      let intervalId = setInterval( this.progressGallery, 5000 );
      // store intervalId in the state so it can be accessed later:
      this.setState( { intervalId: intervalId } );
    }
  }

  componentDidUpdate( prevProps ) {
    if ( this.props.images !== prevProps.images ) {
      this.setState( {
        activeIndex: 0,
      } )
    }
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval( this.state.intervalId );
  }

  progressGallery = () => {
    if ( this.state.activeIndex === this.props.images.length - 1 ) {
      this.setState( {
        activeIndex: 0,
      } );
    } else {
      this.setState( {
        activeIndex: this.state.activeIndex + 1,
      } );
    }
  };

  render() {
    if ( this.props.images.length > 0 ) {
      return (
        <Holder>
          {this.props.images.map( ( image, i ) => {
            return (
              <ImageHolder key={i} active={this.state.activeIndex === i}>
                <GatsbyImage
                  fluid={image.fluid}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  loading="eager"/>
              </ImageHolder>
            )
          } )}
        </Holder>
      )
    } else {
      return null;
    }
  }
}

export default Gallery;
